
import { Component } from 'vue-property-decorator';
import { getComponent, sleep } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
import GenericMixin from '@/lib/mixins/GenericMixin';
import accountModule from '@/store/modules/accountModule';
import { SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    WorkTicketCalendarView: () => getComponent('workTickets/WorkTicketCalendarView'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    WorkTicketVerticalList: () => getComponent('workTickets/WorkTicketVerticalList'),
    PopupView: () => getComponent('tasqsCommon/PopupView'),
    AddWorkTicket: () => getComponent('workTickets/AddWorkTicket'),
    WorkTicketDetail: () => getComponent('workTickets/WorkTicketDetail'),
  },
})
export default class WorkTicketCalenderPage extends mixins(GenericMixin) {
  loadingData = false;

  showAddWorkTicket = false;

  async created() {
    await this.prepareCalendarView();
  }

  async closeTicketDetails() {
    tasqsListModule.setActiveWorkTicket('');
    tasqsListModule.setActiveTasq('');

    if (this.$refs.ticketCalendarView) {
      await sleep(2000);
      // @ts-ignore
      this.$refs.ticketCalendarView.prepareView();
    }
  }

  async prepareCalendarView() {
    this.loadingData = true;
    tasqsListModule.setActiveWorkTicket('');
    tasqsListModule.setActiveTasq('');
    const promises: any = [];

    promises.push(tasqsListModule.getWorkTicketTasqs());
    promises.push(accountModule.getReassignmentList({ useExisting: true }));

    Promise.all(promises)
      .then(async () => {
        await sleep(1000);
        this.loadingData = false;
      })
      .catch(() => {
        this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, {
          text: 'It is not possible to show some list data at this moment,... please try later',
          error: true,
        });
      });
  }

  get activeWorkTicket(): any {
    return tasqsListModule.activeWorkTicket;
  }
}
